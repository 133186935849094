<template>
  <CFooter :fixed="false">
    <div>
      <a href="javascript:void(0)"></a>
      <span class="ml-1">&copy; {{ new Date().getFullYear() }} {{ appTitle }}</span>
    </div>
    <div class="mfs-auto">
      <span class="mr-1" target="_blank">Powered by</span>
      <a href="javascript:void(0)">{{ appTitle }}</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter',
  data() {
    return {
      appTitle: process.env.VUE_APP_NAME,
    };
  },
}
</script>
